.cover-container {
  max-width: 68em;
}

.nav-head .nav-link {
  padding: 0.5rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-bottom: 0.25rem solid transparent;
  transition: transform 0.3s;
}
.active.nav-link:hover,
.active.nav-link:focus {
  border-bottom-color: #fff !important;
  color: #fff !important;
}

.nav-head .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.5);
}
.nav-head .nav-link:hover {
  border-bottom-color: rgba(255, 255, 255, 0.25);
  color: rgba(255, 255, 255, 0.5);
  transform: translateY(5px);
}
.nav-head .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-head .active {
  color: #fff;
  border-bottom-color: #fff;
}
